import React, {useEffect} from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import './App.css';
import LogIn from './component/logIn';
import HeadMenu from "./component/headMenu";
import Market from './component/market';
import NoInputComment from "./component/launchPad/noInputComment";
import TextInputComment from "./component/launchPad/textInputComment";
import {Flex} from 'antd';
import Token from "./component/launchPad/token";
import TokenFee from "./component/launchPad/fee";
import User from "./component/launchPad/user";
import MockComment from "./component/launchPad/mockComment";
import Statistic from "./component/launchPad/statistic"
function App() {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const handleLogin = () => {
    setLoggedIn(true)
  };
  const handleLogout = () => {
    setLoggedIn(false)
  };
  const token = localStorage.getItem("token");
  useEffect(() => {
    console.log("token...", token);
    if(token && token !== "") setLoggedIn(true)
  }, [])
  const PrivateRoute = ( {path, element}:any ) => {
    // return token && token !== "" ? path !== "/login" ? element : <Navigate to="/index" /> : <Navigate to="/login" />
    if(loggedIn) {
      if( path !== "/login") return element
      else return <Navigate to="/index" />
    } else if(path === "/login") return element
    else return <Navigate to="/login" />
  }
  return (
    <BrowserRouter>
      <div className="App">
        <Flex justify='space-between'>
            <HeadMenu isLoggedIn={loggedIn} setLogout={handleLogout} />
            <main style={{flex:"1"}}>
                <Routes>
                    <Route path="/login" element={<PrivateRoute path="/login" element={<LogIn setLogin={handleLogin} />} />} />
                    <Route path="/" element={<Navigate to="/login" />} />
                    {/*<Route path="/index/market" element={<PrivateRoute path="/index/market" element={<Market />} />} />*/}
                    <Route path="/index/banner" element={<PrivateRoute path="/index" element={<Market />} />} />
                    <Route path="/index/noInputComment" element={<PrivateRoute path="/index/noInputComment" element={<NoInputComment />} />} />
                    <Route path="/index/textInputComment" element={<PrivateRoute path="/index/noInputComment" element={<TextInputComment />} />} />

                    <Route path="/index/token" element={<PrivateRoute path="/index/token" element={<Token />} />} />
                    <Route path="/index/fee" element={<PrivateRoute path="/index/fee" element={<TokenFee />} />} />
                    <Route path="/index/user" element={<PrivateRoute path="/index/user" element={<User />} />} />
                    <Route path="/index/mockComment" element={<PrivateRoute path="/index/mockComment" element={<MockComment />} />} />
                    <Route path="/index/statis" element={<PrivateRoute path="/index/statis" element={<Statistic />} />} />
                </Routes>
            </main>
        </Flex>
      </div>
    </BrowserRouter>
  );
}

export default App;
