import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@rainbow-me/rainbowkit/styles.css';
import { connectorsForWallets,
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider, } from '@rainbow-me/rainbowkit';
import { injectedWallet, metaMaskWallet, okxWallet, rainbowWallet, tokenPocketWallet, walletConnectWallet,coinbaseWallet } from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  zora,
  goerli,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

const defaultChains=process.env.REACT_APP_ENABLE_TESTNETS == 'true' ? [goerli] : [mainnet]
const { chains, publicClient, webSocketPublicClient } = configureChains(
    [...defaultChains],
    [publicProvider()]
);
const projectId="2a942b62221e6bcab21616a4de7dbefb"
const { connectors: defaultConnectors } = getDefaultWallets({
  appName: 'RETH',
  projectId: '2a942b62221e6bcab21616a4de7dbefb',
  chains,
});
const wallets = [
  // injectedWallet({ chains }),
  // rainbowWallet({ projectId, chains }),
  metaMaskWallet({ projectId, chains }),
  // walletConnectWallet({ projectId, chains }),
  // tokenPocketWallet({ projectId, chains }),
  // okxWallet({ projectId, chains }),
  // coinbaseWallet({ chains, appName: 'reth.cc' }),
]

const connectors = connectorsForWallets([
  {
    groupName: 'Popular',
    wallets,
  },
])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: connectors,
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
