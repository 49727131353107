import React, {useEffect} from 'react';
import {Flex, Card, Space, Button, Select, Input, Table, Form, message, Modal, Upload, UploadProps, Switch} from 'antd';
import type { SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
    delComment,
    deleteConfig,
    getTextComments,
    getTokenList,
    postComment,
    updateToken, updateTokenBlack,
    updateTokenWhite
} from "../../api";
import {useLocation} from "react-router-dom";
import {PlusOutlined} from "@ant-design/icons";
import {element} from "@rainbow-me/rainbowkit/dist/css/reset.css";


const Token = () => {
    const columns: ColumnsType<any> = [
        {
            title: 'token名称',
            dataIndex: 'tokenName',
            key: 'tokenName'
        },
        {
            title: 'token图片',
            dataIndex: 'tokenLogoUrl',
            key: 'tokenLogoUrl',
            render:(text)=><img src={text} style={{"width":"100px","height":"100px"}}/>
        },
        {
            title: '合约地址',
            dataIndex: 'tokenAddress',
            key: 'tokenAddress'
        },
        {
            title: '白名单',
            dataIndex: 'isWhitelist',
            key: 'isWhitelist',
            render:(text)=><div>
                {text?"是":"否"}
            </div>
        },
        {
            title: '黑名单',
            dataIndex: 'isBlacklist',
            key: 'isBlacklist',
            render:(text)=><div>
                {text?"是":"否"}
            </div>
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            render:(text,record)=><div>
                {record.isBlacklist?<Button type="primary" onClick={()=>{handleBlack(record)}} style={{marginLeft: "40px"}}>移出黑名单</Button>:<Button type="primary" onClick={()=>{handleBlack(record)}} style={{marginLeft: "40px"}}>加入黑名单</Button>}
                {record.isWhitelist?<Button type="primary" onClick={()=>{handleWhite(record)}} style={{marginLeft: "40px"}}>移出白名单</Button>:<Button type="primary" onClick={()=>{handleWhite(record)}} style={{marginLeft: "40px"}}>加入白名单</Button>}
            </div>
        },
    ]
    const { pathname } = useLocation();
    const [comment, setComment] = React.useState("");
    const [tableData, setTableData] = React.useState<any>([]);
    const [page,setPage]=React.useState(0)
    // const [isModalOpen, setIsModalOpen] = React.useState(false);
    // const [modalData, setModalData] = React.useState({ tokenName: "", tokenAddress: "",tokenLogoUrl:"",id:"",isBlacklist:false,isWhitelist:false });
    const getData = async function(page:any){
        const wl:any = await getTokenList({
            page:page,
            searchValue:comment
        });
        setTableData(wl.data)
        setPage(wl.data.count);
    }
    const query = async function(){
        const wl:any = await getTokenList({
            page:1,
            searchValue:comment
        });
        setTableData(wl.data)
        // setPage(wl.data.count);
    }
    const changePage=async (page:any)=>{
        const wl:any = await getTokenList({
            page:page,
            searchValue:comment
        });
        setTableData(wl.data)
        // setPage(wl.data.count);
    }

    // const handleEdit =  function(id:string){
    //     const findComment = tableData.find((element:any)=>element.id===id);
    //     if(findComment){
    //         setModalData({tokenName: findComment.tokenName,tokenAddress: findComment.tokenAddress,tokenLogoUrl:findComment.tokenLogoUrl,id:findComment.id,isBlacklist: findComment.isBlacklist,isWhitelist: findComment.isWhitelist});
    //         setIsModalOpen(true)
    //     }
    // }
    const handleBlack = async function(token:any){
        const res:any = await updateTokenBlack({tokenId:token.coingeckoId,isBlacklist:!token.isBlacklist})
        if(res.code===1){
            message.open({
                type: 'success',
                content: 'update success',
            });
            await getData(1)
        }
    }
    const handleWhite = async function(token:any){
        const res:any = await updateTokenBlack({tokenId:token.coingeckoId,isWhiteList:!token.isWhiteList})
        if(res.code===1){
            message.open({
                type: 'success',
                content: 'update success',
            });
            await getData(1)
        }
    }
    // const handleOk = async () => {
    //     const res = await updateToken({tokenId:modalData.id,isBlacklist:modalData.isBlacklist,isWhitelist:modalData.isWhitelist})
    //     setIsModalOpen(false);
    //     message.open({
    //         type: 'success',
    //         content: 'update success',
    //     });
    //     setModalData({tokenName: "",tokenAddress: "",tokenLogoUrl:"",id:"",isBlacklist: false,isWhitelist: false});
    //     getData(1);
    // };
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    //     setModalData({tokenName: "",tokenAddress: "",tokenLogoUrl:"",id:"",isBlacklist: false,isWhitelist: false});
    // };
    // const setInputValue = function(e:any){
    //     setModalData({...modalData,isBlacklist:e.target.value});
    // }
    // const onChangeIsWhiteList = function(checked:boolean){
    //     setModalData({...modalData,isWhitelist: checked});
    // }
    // const onChangeIsBackList = function(checked:boolean){
    //     setModalData({...modalData,isBlacklist: checked});
    // }
    useEffect(() => {
        const fetchData = async () =>{
            if(pathname){
                getData(1)
            }
        }
        fetchData();
    }, [pathname]);
    return (
        <>
            <Card>
                <Flex vertical>
                    <Flex>
                        <Space size="middle">
                            <Form.Item
                                label="token名称或者合约地址"
                            >
                                <Input style={{width:"200px"}} value={comment} onChange={(e)=>{setComment(e.target.value)}}/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" style={{width:"100px"}} onClick={()=>{query()}}>查询</Button>
                            </Form.Item>
                        </Space>
                    </Flex>
                    {/*<Flex>*/}
                    {/*    <Space size="middle">*/}
                    {/*        <Form.Item>*/}
                    {/*            <Button type="primary" style={{width:"100px"}} onClick={()=>{setIsModalOpen(true)}}>添加</Button>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Space>*/}
                    {/*</Flex>*/}
                    <Table rowKey={(record) => record.coingeckoId} columns={columns} dataSource={tableData} pagination={{
                        total: page,
                        pageSize:50,
                        showSizeChanger:false,
                        onChange: (page, pageSize) => {
                            changePage(page);
                        },
                    }}/>
                </Flex>
            </Card>
            {/*<Modal title="新增/编辑token信息" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>*/}
            {/*    <Form*/}
            {/*        labelCol={{ span: 4 }}*/}
            {/*        wrapperCol={{ span: 14 }}*/}
            {/*        layout="horizontal"*/}
            {/*        style={{ maxWidth: 600 }}*/}
            {/*    >*/}
            {/*        <Form.Item label="代币名称" >*/}
            {/*            <Input value={modalData.tokenName} disabled={true} onChange={setInputValue} defaultValue={modalData.tokenName}/>*/}
            {/*        </Form.Item>*/}

            {/*        <Form.Item label="合约地址" >*/}
            {/*            <Input value={modalData.tokenAddress} disabled={true} onChange={setInputValue} defaultValue={modalData.tokenAddress}/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item label="是否白名单"  >*/}
            {/*            <Switch checkedChildren="是" unCheckedChildren="否" value={modalData.isWhitelist} defaultChecked={modalData.isWhitelist} onChange={onChangeIsWhiteList}/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item label="是否黑名单" >*/}
            {/*            <Switch checkedChildren="是" unCheckedChildren="否" value={modalData.isBlacklist} defaultChecked={modalData.isBlacklist} onChange={onChangeIsBackList}/>*/}
            {/*        </Form.Item>*/}
            {/*    </Form>*/}
            {/*</Modal>*/}
        </>
    )
}
export default Token;
