import { request } from "../utils/request";

const login = <T>(body:any) => {
    return request.post<T>('/sys/login', body);
};
const getConfig = <T>(params:any) => {
    return request.get<T>('/local/banner/list', params);
};
const postConfig = <T>(params:any) => {
    return request.post<T>('/local/banner/edit', params);
}

const postComment = <T>(params:any) => {
    return request.post<T>('/local/comments/edit', params);
}
const deleteConfig = <T>(params:any) => {
    return request.delete<T>(`/local/banner/${params.id}`);
}

const delComment = <T>(params:any) => {
    return request.delete<T>(`/local/comments/del/${params.id}`);
}


const getDefaultComments = <T>(params:any) => {
    return request.get<T>('/local/comments/default', params);
}

const getMockComments = <T>(params:any) => {
    return request.get<T>('/local/comments/mock', params);
}

const getTextComments = <T>(params:any) => {
    return request.get<T>('/local/comments/text', params);
}
const getTokenList = <T>(params:any) => {
    return request.get<T>('/local/token/list', params);
}

const updateToken = <T>(params:any) => {
    return request.post<T>('/local/token/info', params);
}

const updateTokenWhite = <T>(params:any) => {
    return request.post<T>('/local/token/white', params);
}

const updateTokenBlack = <T>(params:any) => {
    return request.post<T>('/local/token/black', params);
}
const updateTokenFee = <T>(params:any) => {
    return request.post<T>('/local/token/fee', params);
}

const getStatistic = <T>() => {
    return request.get<T>('/local/statistics/info');
}


const getUserHonorList = <T>(params:any) => {
    return request.post<T>('/local/user/list', params);
}

const updateUserHonorList = <T>(params:any) => {
    return request.get<T>('/local/user/edit', params);
}
export {
    login,
    getConfig,
    postConfig,
    deleteConfig,
    delComment,
    getDefaultComments,
    getTextComments,
    postComment,
    getTokenList,
    updateToken,
    updateTokenWhite,
    updateTokenFee,
    getUserHonorList,
    updateUserHonorList,
    getMockComments,
    updateTokenBlack,
    getStatistic
 };
