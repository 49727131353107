import React from 'react';

import {Flex, Card, Form, Input, Button, message} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import {login} from "../api/index"
interface LogInProps {
    setLogin: () => void
}
const LogIn: React.FC<LogInProps> = (props) => {
    const { setLogin } = props;

    const [account, setAccount] = React.useState("");
    const [password, setPassword] = React.useState("");
    // const [passwordVisible, setPasswordVisible] = React.useState(false);
    const navigate = useNavigate();
    type FieldType = {
        username?: string;
        password?: string;
        remember?: string;
    };
    const handleLogin = async ()=>{
        let res:any = await login({username:account,password:password});
        if(res.code===200){
            const token = res.token;
            localStorage.setItem("token",token);
            setLogin();
            message.open({
                type: 'success',
                content: 'login success!',
            });
            navigate("/index/market")
        }else{
            message.open({
                type: 'error',
                content: 'login failed,username or password incorret',
            });
        }
    }
    return (
        <>
            <Flex align='center' justify='center' style={{marginTop: "10%"}}>
                <Card>
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        style={{ maxWidth: 600 }}
                    >
                        <Form.Item<FieldType>
                            label="账号"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input value={account} onChange={(e)=>{setAccount(e.target.value)}}/>
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="密码"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password
                                value={password}
                                onChange={(e)=>{setPassword(e.target.value)}}
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>

                            {/* <Form.Item<FieldType>
                            name="remember"
                            valuePropName="checked"
                            wrapperCol={{ offset: 8, span: 16 }}
                            >
                            <Checkbox>Remember me</Checkbox>
                            </Form.Item> */}

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit"
                                 onClick={()=>{handleLogin()}}
                            >
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Flex>
        </>
    )
}
export default LogIn;