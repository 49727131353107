import React from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useNavigate } from "react-router-dom";

const items: MenuProps['items'] = [
    {
      label: 'Banner位',
      key: 'banner',
    },
    {
        label: '无输入弹幕',
        key: 'noInputComment',
    },
    {
        label: '文字弹幕',
        key: 'textComment',
    },
    {
        label: 'mock弹幕',
        key: 'mockComment',
    },
    {
        label: 'token筛选',
        key: 'tokenFilter',
    },
    {
        label: '手续费设置',
        key: 'fee',
    },
    {
        label: '用户信息管理',
        key: 'user',
    },
    {
        label: '统计',
        key: 'statis',
    }
];
interface HeadMenuProps {
    setLogout: () => void,
    isLoggedIn:boolean,
}
const HeadMenu: React.FC<HeadMenuProps> = (props) => {
    const navigate = useNavigate();
    const { isLoggedIn, setLogout } = props;
    const [current, setCurrent] = React.useState('market');
    const onClick: MenuProps['onClick'] = (e) => {
        // console.log('click ', e);
        if(e.key === "banner") navigate("/index/banner");
        else if(e.key === "noInputComment") navigate("/index/noInputComment");
        else if(e.key === "textComment") navigate("/index/textInputComment");
        else if(e.key === "tokenFilter") navigate("/index/token")
        else if(e.key === "fee") navigate("/index/fee")
        else if(e.key === "user") navigate("/index/user")
        else if(e.key === "mockComment") navigate("/index/mockComment")
        else if(e.key === "statis") navigate("/index/statis")
        setCurrent(e.key);
    };
    if(isLoggedIn) {
        return (
            <>
                <Menu style={{ width: 256 }} onClick={onClick} selectedKeys={[current]} mode="vertical" items={items} />
            </>
        )
    } else {
        return (
            <></>
        )
    }

}
export default HeadMenu;
