import React, {useEffect} from 'react';
import { Flex, Card, Space, Upload, Button,Modal,Input,Form,message,UploadProps } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {useLocation} from "react-router-dom";
import {getConfig,postConfig,deleteConfig} from "../api";

const Market = () => {
    const { pathname } = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [items,setItems] = React.useState([]);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [link,setLink] = React.useState("");
    const [image,setImage] = React.useState('')

    const showModal = () => {
        setIsModalOpen(true);
    };
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const handleOk = async () => {
        const res = await postConfig({link:link,image:image})
        setIsModalOpen(false);
        message.open({
            type: 'success',
            content: 'add success',
        });
        getData();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getData = async ()=>{
        try{
            const res:any = await getConfig({page:1});
            console.log(res)
            setItems(res.data)
        } catch(error) {
            console.error("error", error);
        }
    }

    useEffect(() => {
        const fetchData = async () =>{
            if(pathname){
                getData()
            }
        }
        fetchData();
    }, [pathname]);
    const setInputValue = function(e:any){
        setLink(e.target.value)
    }
    const props: UploadProps = {
        beforeUpload: (file) => {
            const isPNG = file.type === 'image/png';
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            if (!isPNG&&!isJPG&&!isJPEG) {
                message.error(`${file.name} is not a image file`);
            }
            return isPNG||isJPG||isJPEG || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
            // console.log(info.fileList);
            if(info&&info.fileList[0].response){
                // console.log(info.fileList[0].thumbUrl)
                setImage(info.fileList[0].response.data)
            }
        },
    };
    const handleDel = async function(id:any){
        const res:any = await deleteConfig({id:id})
        if(res.code==200){
            message.open({
                type: 'success',
                content: 'del success',
            });
        }
        await getData();
    }

    return (
        <>
            <h1>首页轮播图：</h1>
            {/*{items[0]}*/}
            {items?.map(function(value:any,index){
                return (<Card key={value.id} style={{ width: "90%", margin: "10px 0 10px 5%", padding:"20px 50px"}}>
                    <Flex>
                        <Flex vertical >

                            <Flex align='center' justify='space-between'>
                                <a href={value.linkUrl}>
                                <img src={value?.imageUrl} width="800" height="300" style={{display:"block"}} />
                                </a>
                                <Button type="primary" onClick={()=>{handleDel(value?.id)}} style={{marginLeft: "40px"}}>删除</Button>
                            </Flex>
                            {/*<Upload*/}
                            {/*    name="avatar"*/}
                            {/*    listType="picture-card"*/}
                            {/*    className="avatar-uploader"*/}
                            {/*    showUploadList={false}*/}
                            {/*>*/}
                            {/*    {uploadButton}*/}
                            {/*</Upload>*/}

                        </Flex>
                    </Flex>
                </Card>)
            })}
            <Flex align='center' justify='center'>
                <Button type="primary" style={{width:"200px"}} onClick={()=>{setIsModalOpen(true)}}>新增</Button>
            </Flex>
            <Modal title="新增配置" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                    style={{ maxWidth: 600 }}
                >
                    <Form.Item label="link" >
                        <Input value={link} onChange={setInputValue}/>
                    </Form.Item>
                    <Form.Item label="Upload" valuePropName="fileList" getValueFromEvent={normFile}>
                        <Upload action="https://bibobibo.xyz/sys/upload" listType="picture-card" maxCount={1} {...props} showUploadList={{"showPreviewIcon":false,showRemoveIcon:false}} >
                            <button style={{ border: 0, background: 'none' }} type="button">
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </button>
                        </Upload>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    )
}
export default Market;
