import React, {useEffect} from 'react';
import {
    Flex,
    Card,
    Space,
    Button,
    Select,
    Input,
    Table,
    Statistic as StatisticAnt,
    Row,
    Col
} from 'antd';
import type { SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
     getStatistic,
} from "../../api";
import {useLocation} from "react-router-dom";

const Statistic = () => {
    const columns: ColumnsType<any> = [

        {
            title: '合约地址',
            dataIndex: 'contract',
            key: 'contract'
        },
        {
            title: '弹幕数',
            dataIndex: 'commentCount',
            key: 'commentCount'
        },
    ]
    const { pathname } = useLocation();
    const [last24Comments, setLast24Comments] = React.useState(0);
    const [totalComments, setTotalComments] = React.useState(0);
    const [averageComment, setAverageComment] = React.useState(0);
    const [tableData, setTableData] = React.useState<any>([]);
    const getData = async function(){
        const wl:any = await getStatistic();
        setTableData(wl.data.top10TokenComment)
        setLast24Comments(wl.data.last24Comments);
        setTotalComments(wl.data.totalComments);
        setAverageComment(parseInt(wl.data.averageComment));
    }


    useEffect(() => {
        const fetchData = async () =>{
            if(pathname){
                getData()
            }
        }
        fetchData();
    }, [pathname]);
    return (
        <>
            <Card>
                <Flex vertical>
                    <Flex>
                        <Row gutter={16}>
                            <Col >
                                <StatisticAnt title="平台24h弹幕总量" value={last24Comments}  />
                            </Col>
                            <Col>
                                <StatisticAnt title="累计弹幕总数" value={totalComments}  />
                            </Col>
                            <Col>
                                <StatisticAnt title="累计人均弹幕数量" value={averageComment}  />
                            </Col>
                        </Row>
                    </Flex>

                    <Table rowKey={(record) => record.contract} columns={columns} dataSource={tableData} />
                </Flex>
            </Card>

        </>
    )
}
export default Statistic;
