import React, {useEffect} from 'react';
import {Flex, Card, Space, Button, Select, Input, Table, Form, message, Modal, Upload, UploadProps} from 'antd';
import type { SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
    delComment,
    deleteConfig,
    getTextComments,
    getTokenList,
    postComment,
    updateToken,
    updateTokenFee
} from "../../api";
import {useLocation} from "react-router-dom";
import {PlusOutlined} from "@ant-design/icons";
import {element} from "@rainbow-me/rainbowkit/dist/css/reset.css";


const Token = () => {
    const columns: ColumnsType<any> = [
        {
            title: 'token名称',
            dataIndex: 'tokenName',
            key: 'tokenName'
        },
        {
            title: 'token图片',
            dataIndex: 'tokenLogoUrl',
            key: 'tokenLogoUrl',
            render:(text)=><img src={text} style={{"width":"100px","height":"100px"}}/>
        },
        {
            title: '合约地址',
            dataIndex: 'tokenAddress',
            key: 'tokenAddress'
        },
        {
            title: '白名单',
            dataIndex: 'isWhitelist',
            key: 'isWhitelist',
            render:(text)=><div>
                {text==false?"否":"是"}
            </div>
        },
        {
            title: '黑名单',
            dataIndex: 'isBlacklist',
            key: 'isBlacklist',
            render:(text)=><div>
                {text==false?"否":"是"}
            </div>
        },
        {
            title: '费率',
            dataIndex: 'fee',
            key: 'fee'
        },
        {
            title: '操作',
            dataIndex: 'coingeckoId',
            key: 'coingeckoId',
            render:(text,recorde)=><div>
                <Button type="primary" onClick={()=>{handleEdit(recorde)}} style={{marginLeft: "40px"}}>编辑</Button>
            </div>
        },
    ]
    const { pathname } = useLocation();
    const [comment, setComment] = React.useState("");
    const [tableData, setTableData] = React.useState<any>([]);
    const [page,setPage]=React.useState(0)
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [modalData, setModalData] = React.useState({ input: "", id: "" });
    const getData = async function(page:any){
        const wl:any = await getTokenList({
            page:page,
            searchValue:comment
        });
        setTableData(wl.data)
    }
    const query = async function(){
        const wl:any = await getTokenList({
            page:1,
            searchValue:comment
        });
        setTableData(wl.data)
        // setPage(wl.data.count);
    }
    const changePage=async (page:any)=>{
        const wl:any = await getTokenList({
            page:page,
            searchValue:comment
        });
        setTableData(wl.data)
        // setPage(wl.data.count);
    }

    const handleEdit =  function(record:any){
        // const findComment = tableData.find((element:any)=>element.id===id);
        // if(findComment){
            setModalData({ input: record.fee, id: record.coingeckoId });
            // console.log(modalData)
            setIsModalOpen(true)
        // }
    }
    const addWLHanlder = async function(){
        // const res:any = await addWL({})
        // if(res.code==1){
        //     message.success("add wl success")
        // }
        // getData(1);
    }
    const handleOk = async () => {
        const res = await updateTokenFee({tokenId:modalData.id,fee:modalData.input})
        setIsModalOpen(false);
        message.open({
            type: 'success',
            content: 'fee update success',
        });
        setModalData({input:"",id:""})
        getData(1);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setModalData({input:"",id:""})
    };
    const setInputValue = function(e:any){
        setModalData({ ...modalData, input: e.target.value });
    }
    useEffect(() => {
        const fetchData = async () =>{
            if(pathname){
                getData(1)
            }
        }
        fetchData();
    }, [pathname]);
    return (
        <>
            <Card>
                <Flex vertical>
                    <Flex>
                        <Space size="middle">
                            <Form.Item
                                label="token名称或者合约地址"
                            >
                                <Input style={{width:"200px"}} value={comment} onChange={(e)=>{setComment(e.target.value)}}/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" style={{width:"100px"}} onClick={()=>{query()}}>查询</Button>
                            </Form.Item>
                        </Space>
                    </Flex>
                    {/*<Flex>*/}
                    {/*    <Space size="middle">*/}
                    {/*        <Form.Item>*/}
                    {/*            <Button type="primary" style={{width:"100px"}} onClick={()=>{setIsModalOpen(true)}}>添加</Button>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Space>*/}
                    {/*</Flex>*/}
                    <Table rowKey={(record) => record.coingeckoId} columns={columns} dataSource={tableData} pagination={{
                        total: page,
                        pageSize:50,
                        showSizeChanger:false,
                        onChange: (page, pageSize) => {
                            changePage(page);
                        },
                    }}/>
                </Flex>
            </Card>
            <Modal title="token费率修改" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                    style={{ maxWidth: 600 }}
                >
                    <Form.Item label="token费率"  >
                        <Input value={modalData.input} onChange={setInputValue} type="number" defaultValue={modalData.input}/>
                    </Form.Item>


                </Form>
            </Modal>

        </>
    )
}
export default Token;
