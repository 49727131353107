import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { requestBaseUrl } from "./common";
import { useNavigate } from "react-router-dom";

let baseURL = requestBaseUrl;

const timeout = 600000;

const service = axios.create({
  timeout,
  baseURL
});

// service.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
// service.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("token");
axios.defaults.withCredentials = true;
interface axiosTypes<T>{
  data: T;
  status: number;
  statusText: string;
}

const requestHandler = <T>(method: 'get' | 'post' | 'put' | 'delete', url: string, params: object = {}, config: AxiosRequestConfig = {}): Promise<T> => {
  let response: Promise<axiosTypes<T>>;
  service.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("token");
  switch(method){
    case 'get':
      response = service.get(url, {params: { ...params }, ...config});
      break;
    case 'post':
      response = service.post(url, {...params}, {...config});
      break;
    case 'put':
      response = service.put(url, {...params}, {...config});
      break;
    case 'delete':
      response = service.delete(url, {params: { ...params }, ...config});
      break;
  }

  return new Promise<T>((resolve, reject) => {
    response.then(res => {
      const data = res.data;
      if(res.status !== 200){
        if(res.status === 401){
          console.log(`logout or timeout`);
          localStorage.setItem("token", "");
          window.location.hash = "/login";
        }
        let e = JSON.stringify(res.data);
        console.log(`request error: ${e}`)
        reject(data);
      }else{
        resolve(data);
      }

    }).catch(error => {
      let e = JSON.stringify(error);
      console.log(`network error: ${e}`)
      if(error.response && error.response.status === 401){
        console.log(`logout or timeout`);
        localStorage.setItem("token", "");
        window.location.href = "/login";
        return;
      }
      reject(error);
    })
  })
}

const request = {
  get: <T>(url: string, params?: object, config?: AxiosRequestConfig) => requestHandler<T>('get', url, params, config),
  post: <T>(url: string, params?: object, config?: AxiosRequestConfig) => requestHandler<T>('post', url, params, config),
  put: <T>(url: string, params?: object, config?: AxiosRequestConfig) => requestHandler<T>('put', url, params, config),
  delete: <T>(url: string, params?: object, config?: AxiosRequestConfig) => requestHandler<T>('delete', url, params, config)
};

export { request };
