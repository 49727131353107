import React, {useEffect} from 'react';
import {Flex, Card, Space, Button, Input, Table, Form, message, Modal, Upload, UploadProps} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {delComment, getDefaultComments, postComment} from "../../api";
import {useLocation} from "react-router-dom";
import {PlusOutlined} from "@ant-design/icons";

const NoInputComment = () => {
    const columns: ColumnsType<any> = [
        {
            title: '名称',
            dataIndex: 'text',
            key: 'text'
        },
        {
            title: '弹幕图片',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render:(text)=><img src={text} style={{"width":"100px","height":"100px"}}/>
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            render:(text)=><div>
                <Button type="primary" onClick={()=>{handleEdit(text)}} style={{marginLeft: "40px"}}>编辑</Button>
                <Button type="default" onClick={()=>{handleDel(text)}} style={{marginLeft: "40px"}}>删除</Button>
            </div>
        },
    ];

    const { pathname } = useLocation();
    const [comment, setComment] = React.useState("");
    const [tableData, setTableData] = React.useState<any>([]);
    const [page,setPage]=React.useState(0);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [modalData, setModalData] = React.useState({ input: "", image: "",id:"" });

    const getData = async function(page:any){
        const wl:any = await getDefaultComments({
            page:page
        });
        setTableData(wl.data.rows);
        setPage(wl.data.count);
    }

    const query = async function(){
        const wl:any = await getDefaultComments({
            page:1,
            comment:comment
        });
        setTableData(wl.data.rows);
        setPage(wl.data.count);
    }

    const changePage = async (page:any) => {
        const wl:any = await getDefaultComments({
            page:page
        });
        setTableData(wl.data.rows);
        setPage(wl.data.count);
    }

    const handleDel = async function(id:any){
        const res:any = await delComment({id:id});
        if(res.code === 1){
            message.success('删除成功');
        }
        await getData(1);
    }

    const handleEdit = async function(id:string){
        const findComment = tableData.find((element:any) => element.id === id);
        if(findComment){
            setModalData({input:findComment.text,image:findComment.imageUrl,id:findComment.id})
            setIsModalOpen(true);
        }
    }

    const handleOk = async () => {
        const res = await postComment({text:modalData.input, imageUrl:modalData.image, commentType:"default",commentId:modalData.id});
        setIsModalOpen(false);
        message.success('添加成功');
        setModalData({input:"",image:"",id:""})
        getData(1);
    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const props: UploadProps = {
        beforeUpload: (file) => {
            const isImage = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';
            if (!isImage) {
                message.error(`${file.name} 不是图片文件`);
            }
            return isImage || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
            if(info && info.fileList[0].response){
                setModalData({...modalData,image:info.fileList[0].response.data});
            }
        },
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setModalData({input:"",image:"",id:""})
    };

    const setInputValue = (e:any) => {
        setModalData({ ...modalData, input: e.target.value });
    };

    useEffect(() => {
        const fetchData = async () => {
            if(pathname){
                await getData(1);
            }
        }
        fetchData();
    }, [pathname]);

    return (
        <>
            <Card>
                <Flex vertical>
                    <Flex>
                        <Space size="middle">
                            <Form.Item label="弹幕名称">
                                <Input style={{width:"200px"}} value={comment} onChange={(e)=>setComment(e.target.value)} />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" style={{width:"100px"}} onClick={query}>查询</Button>
                            </Form.Item>
                        </Space>
                    </Flex>
                    <Flex>
                        <Space size="middle">
                            <Form.Item>
                                <Button type="primary" style={{width:"100px"}} onClick={()=>setIsModalOpen(true)}>添加</Button>
                            </Form.Item>
                        </Space>
                    </Flex>
                    <Table rowKey={(record) => record.id} columns={columns} dataSource={tableData} pagination={{
                        total: page,
                        pageSize:50,
                        showSizeChanger:false,
                        onChange: (page, pageSize) => changePage(page),
                    }}/>
                </Flex>
            </Card>
            <Modal title="新增/编辑无输入弹幕" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal" style={{ maxWidth: 600 }}>
                    <Form.Item label="弹幕名称">
                        <Input value={modalData.input} onChange={setInputValue} defaultValue={modalData.input}/>
                    </Form.Item>
                    <Form.Item label="图片" valuePropName="fileList" getValueFromEvent={normFile}>
                        {modalData.image?<img src={modalData.image}/>:""}
                        <Upload action="https://bibobibo.xyz/sys/upload" listType="picture-card" maxCount={1} {...props} showUploadList={{"showPreviewIcon":false, showRemoveIcon:false}}>
                            <button style={{ border: 0, background: 'none' }} type="button">
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>上传</div>
                            </button>
                        </Upload>

                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default NoInputComment;
