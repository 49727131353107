const requestBaseUrl = process.env.REACT_APP_REQUEST_BASE_URL;//正式环境
// const requestBaseUrl = "http://3.129.208.49/";//测试环境
const pageSize = 50;
const networkChainId = Number(process.env.REACT_APP_CHAINID) || 1;

const indexContractAddress = process.env.REACT_APP_INDEX_CONTRACT_ADDRESS || "";
const launchpadContractAddress = process.env.REACT_APP_LAUNCHPAD_CONTRACT_ADDRESS || "";
const launchpadContractAbi = [{
    "inputs": [
        {
            "internalType": "address",
            "name": "_caller",
            "type": "address"
        }
    ],
    "name": "isCallerAuthorized",
    "outputs": [
        {
            "internalType": "bool",
            "name": "",
            "type": "bool"
        }
    ],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [
        {
            "internalType": "bytes32",
            "name": "ethsClassHash",
            "type": "bytes32"
        },
        {
            "internalType": "uint256",
            "name": "amt",
            "type": "uint256"
        }
    ],
    "name": "setClaimEthscript",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}]
const listMode = "Card";
const isMobile = () => {
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return navigator.userAgent.match(mobileRegex);
};
const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const randomString = (length: number) => {
    let result = "";
    for (let i = length; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
}
export {
    requestBaseUrl,
    pageSize,
    networkChainId,
    indexContractAddress,
    listMode,
    isMobile,
    randomString,
    launchpadContractAbi,
    launchpadContractAddress
};
