import React, {useEffect} from 'react';
import {Flex, Card, Space, Button, Select, Input, Table, Form, message, Modal, Upload, UploadProps, Tag} from 'antd';
import type { SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {delComment, getTextComments, getUserHonorList, updateUserHonorList} from "../../api";
import {useLocation} from "react-router-dom";
import {PlusOutlined} from "@ant-design/icons";
import {element} from "@rainbow-me/rainbowkit/dist/css/reset.css";


const User = () => {
    const columns: ColumnsType<any> = [
        {
            title: '钱包地址',
            dataIndex: 'walletAddress',
            key: 'walletAddress'
        },
        {
            title: '用户名',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: '头像',
            dataIndex: 'avatarUrl',
            key: 'avatarUrl',
            render:(text)=><img src={text} style={{"width":"100px","height":"100px"}}/>
        },
        {
            title: '荣誉',
            dataIndex: 'honors',
            key: 'honors',
            render:(honors)=> <>
                {honors.map((honor: any, index: number) => (
                    <div key={index}>
                        <Tag>{honor.honorType}</Tag>
                        {/*{honor.honorLevel && <span> - {honor.honorLevel}</span>}*/}
                    </div>
                ))}
            </>
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            render:(text)=><div>
                <Button type="primary" onClick={()=>{handleEdit(text)}} style={{marginLeft: "40px"}}>编辑</Button>
            </div>
        },
    ]
    const { pathname } = useLocation();
    const [comment, setComment] = React.useState("");
    const [tableData, setTableData] = React.useState<any>([]);
    const [page,setPage]=React.useState(0)
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [tags,setTags] = React.useState([{id:"",honorType:""}])
    let removeTags:any = [];
    const getData = async function(page:any){
        const wl:any = await getUserHonorList({
            page:page
        });
        setTableData(wl.data.rows)
        setPage(wl.data.count);
    }
    const query = async function(){
        const wl:any = await getTextComments({
            page:1,
            comment:comment
        });
        setTableData(wl.data.rows)
        setPage(wl.data.count);
    }
    const changePage=async (page:any)=>{
        const wl:any = await getTextComments({
            page:page
        });
        setTableData(wl.data.rows)
        setPage(wl.data.count);
    }
    const handleEdit =  function(id:string){
        const findComment = tableData.find((element:any)=>element.id===id);
        if(findComment){
            setTags(findComment.honors);
            setIsModalOpen(true)
        }
    }
    const addWLHanlder = async function(){
        // const res:any = await addWL({})
        // if(res.code==1){
        //     message.success("add wl success")
        // }
        // getData(1);
    }
    const handleOk = async () => {
        if(removeTags.length!==0){
            const res = await updateUserHonorList({honor:removeTags})
            setIsModalOpen(false);
            message.open({
                type: 'success',
                content: 'honor update success',
            });
            setTags([])
            getData(1);
        }else{
            setIsModalOpen(false);
        }
    };


    const handleCancel = () => {
        setIsModalOpen(false);
        setTags([])
    };
    const handleTagClose = (removedTag: string) => {
        const newTags = tags.filter((tag) => tag.id !== removedTag);
        removeTags.push(removedTag)
        setTags(newTags);
    };
    useEffect(() => {
        const fetchData = async () =>{
            if(pathname){
                getData(1)
            }
        }
        fetchData();
    }, [pathname]);
    return (
        <>
            <Card>
                <Flex vertical>
                    <Flex>
                        <Space size="middle">
                            <Form.Item
                                label="钱包地址"
                            >
                                <Input style={{width:"200px"}} value={comment} onChange={(e)=>{setComment(e.target.value)}}/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" style={{width:"100px"}} onClick={()=>{query()}}>查询</Button>
                            </Form.Item>
                        </Space>
                    </Flex>
                    <Table rowKey={(record) => record.id} columns={columns} dataSource={tableData} pagination={{
                        total: page,
                        pageSize:50,
                        showSizeChanger:false,
                        onChange: (page, pageSize) => {
                            changePage(page);
                        },
                    }}/>
                </Flex>
            </Card>
            <Modal title="修改用户荣誉墙" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                    style={{ maxWidth: 600 }}
                >
                    {tags.map((tag, index) => {
                        return (<Tag
                            key={index}
                            closable={index !== 0}
                            style={{ userSelect: 'none' }}
                            onClose={() => handleTagClose(tag.id)}
                        >
                            {tag.honorType}
                        </Tag>)
                    })}

                </Form>
            </Modal>

        </>
    )
}
export default User;
